import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Button, Space, Statistic, Table, Tag } from 'antd';
import Loading from '../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import {
   getCommission,
   getRecommendCommission,
} from '../features/commission/commissionSlice';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import commaNumber from 'comma-number';

const BonusMatchingSummaryNew = () => {
   const navigate = useNavigate();

   const { date, amount1, amount2 } = useParams();

   const date2 = moment(date).format('DD/MM/YYYY');

   let result1 = parseInt(amount1);
   let result2 = parseInt(amount2);

   let Data2 = [];

   if (amount1 || amount2) {
      Data2.push({ amount: result1, id: 1 }, { amount: result2, id: 2 });
   }
   const [loadingPage, setLoadingPage] = useState(true);
   const {
      commissions,
      reccommendCommsion,
      isError,
      isSuccess,
      message,
      isLoading,
   } = useSelector((state) => state.commission);

   const dispatch = useDispatch();

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

    //  dispatch(getRecommendCommission());
   }, []);

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'PV',
         dataIndex: 'amount',
         key: 'amount',
         render: (value, record) => (
            <>
               {record.id === 1 ? (
                  <Link to={`/matchingDetailNew/${date}/${record.id}`}>
                     <span style={{ fontSize: '14px' }}>
                        <b>{commaNumber(value)} </b>
                     </span>
                     <span>
                        <b>฿</b>
                     </span>
                  </Link>
               ) : (
                  <Link to={`/matchingDetailNew/${date}/${record.id}`}>
                     <span style={{ fontSize: '14px' }}>
                        {' '}
                        <b>{commaNumber(value)} </b>
                        <span>
                           <b>฿</b>
                        </span>
                     </span>
                  </Link>
               )}
            </>
         ),
      },
      {
         title: '%',
         render: (text) => (
            <span style={{ fontSize: '14px' }}>
               <b>5% </b>
            </span>
         ),
      },
      {
         title: 'โบนัส',
         dataIndex: 'amount',
         key: 'amount',
         render: (value) => (
            <>
               <span style={{ fontSize: '14px' }}>
                  <b> {commaNumber(value * (5 / 100))}</b>
                  <span>
                  <b>฿</b>
               </span>
               </span>
              
            </>
         ),
      },
   ];

   return (
      <DefaultLayout>
         {loadingPage ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <Container>
               <div className='my-5'>
                  <h3>
                     {' '}
                     <b>ที่มาโบนัสแมทชิ่ง วันที่: {date}</b>
                  </h3>
               </div>
               <div className='d-flex justify-content-start '>
                  <Button
                     type='danger'
                     style={{ backgroundColor: '#eb2632', color: 'white' }}
                     size='large'
                     onClick={() => navigate(-1)}
                  >
                     {' '}
                     กลับไป{' '}
                  </Button>
               </div>
               <br />
               <Table
                  summary={(Data2) => {
                     let totalAmount = 0;
                     Data2.forEach(({ amount }) => {
                        totalAmount += amount;
                     });

                     return (
                        <>
                           <Table.Summary.Row>
                              <Table.Summary.Cell>
                                 {' '}
                                 <b> รวม</b>{' '}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell type='danger'>
                                
                                 <b>
                                    {commaNumber(totalAmount)}
                                 </b>
                                 <b>฿</b>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell type='danger'></Table.Summary.Cell>
                              <Table.Summary.Cell type='danger'>
                                    <span> <b>{commaNumber((totalAmount * 5) / 100)} ฿</b></span>
                                     
                                    </Table.Summary.Cell>
                           </Table.Summary.Row>
                        </>
                     );
                  }}
                  columns={columns}
                  dataSource={Data2}
                  pagination={false}
               />
            </Container>
         )}
      </DefaultLayout>
   );
};

export default BonusMatchingSummaryNew;




// TEST NEW //

// import React, { useEffect, useState } from 'react';
// import DefaultLayout from '../components/DefaultLayout';
// import { Button, Space, Statistic, Table, Tag } from 'antd';
// import Loading from '../components/Loading';
// import { useDispatch, useSelector } from 'react-redux';

// import moment from 'moment';
// import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import { Container } from 'react-bootstrap';
// import commaNumber from 'comma-number';
// import { getMatchingHistoryDetail } from '../features/commission/commissionSlice';

// const BonusMatchingSummaryNew = () => {
//   const navigate = useNavigate();

//   const { date, amount1, amount2 } = useParams();

//   let result1 = parseInt(amount1);
//   let result2 = parseInt(amount2);

//   let Data2 = [];

//   if (amount1 || amount2) {
//     Data2.push({ amount: result1, id: 1 }, { amount: result2, id: 2 });
//   }
//   const [loadingPage, setLoadingPage] = useState(true);
//   const { bonusMatchingDetail } = useSelector((state) => state.commission);

//   let result = null;

//   const { filteredCommission1, filteredCommission2 } = bonusMatchingDetail;

//   if (filteredCommission1 || filteredCommission2) {
//     const combinedCommissions = [
//       ...filteredCommission1.flat().map((item) => ({ ...item, level: 1 })),
//       ...filteredCommission2.map((item) => ({ ...item, level: 2 })),
//     ];

//     const totalsByLevel = combinedCommissions.reduce(
//       (acc, item) => {
//         const levelKey = `level${item.level}`;
//         acc[levelKey].pv += item.amount; // Add PV
//         acc[levelKey].bonus += item.amount * 0.05; // Calculate 5% bonus
//         return acc;
//       },
//       {
//         level1: { pv: 0, bonus: 0 },
//         level2: { pv: 0, bonus: 0 },
//       }
//     );

//     result = [
//       {
//         level: 1,
//         pv: totalsByLevel.level1.pv,
//         percentage: '5%',
//         bonus: totalsByLevel.level1.bonus.toFixed(2),
//       },
//       {
//         level: 2,
//         pv: totalsByLevel.level2.pv,
//         percentage: '5%',
//         bonus: totalsByLevel.level2.bonus.toFixed(2),
//       },
//     ];
//   }

//   console.log('result:', result);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (loadingPage) {
//       setTimeout(() => {
//         setLoadingPage(false);
//       }, 1500);
//     }

//     dispatch(getMatchingHistoryDetail(date));
//   }, []);

//   const columns = [
//     {
//       title: 'No',
//       dataIndex: 'level',
//       key: 'level',
//     },
//     {
//       title: 'PV',
//       dataIndex: 'pv',
//       key: 'pv',
//       render: (value, record) => (
//         <>
//           {record.id === 1 ? (
//             <Link to={`/matchingDetailNew/${date}/${record.id}`}>
//               <span style={{ fontSize: '14px' }}>
//                 <b>{commaNumber(value)} </b>
//               </span>
//               <span>
//                 <b>$</b>
//               </span>
//             </Link>
//           ) : (
//             <Link to={`/matchingDetailNew/${date}/${record.level}`}>
//               <span style={{ fontSize: '14px' }}>
//                 {' '}
//                 <b>{commaNumber(value)} </b>
//                 <span>
//                   <b>$</b>
//                 </span>
//               </span>
//             </Link>
//           )}
//         </>
//       ),
//     },
//     {
//       title: '%',
//       render: (text) => (
//         <span style={{ fontSize: '14px' }}>
//           <b>5% </b>
//         </span>
//       ),
//     },
//     {
//       title: 'Bonus',
//       dataIndex: 'pv',
//       key: 'pv',
//       render: (value) => (
//         <>
//           <span style={{ fontSize: '14px' }}>
//             <b> {commaNumber(value * (5 / 100))}</b>
//             <span>
//               <b>฿</b>
//             </span>
//           </span>
//         </>
//       ),
//     },
//   ];

//   return (
//     <DefaultLayout>
//       {loadingPage ? (
//         <>
//           <Loading />{' '}
//         </>
//       ) : (
//         <Container>
//           <div className='my-5'>
//             <h3>
//               {' '}
//               <b>Matching Bonus Date: {date}</b>
//             </h3>
//           </div>
//           <div className='d-flex justify-content-start '>
//             <Button
//               type='danger'
//               style={{ backgroundColor: '#eb2632', color: 'white' }}
//               size='large'
//               onClick={() => navigate(-1)}
//             >
//               Go back
//             </Button>
//           </div>
//           <br />
//           <Table
//             summary={(result) => {
//               let totalAmount = 0;
//               result.forEach(({ pv }) => {
//                 totalAmount += pv;
//               });

//               return (
//                 <>
//                   <Table.Summary.Row>
//                     <Table.Summary.Cell>
//                       {' '}
//                       <b> Total</b>{' '}
//                     </Table.Summary.Cell>
//                     <Table.Summary.Cell type='danger'>
//                       <b>{commaNumber(totalAmount)}</b>
//                       <b>$</b>
//                     </Table.Summary.Cell>
//                     <Table.Summary.Cell type='danger'></Table.Summary.Cell>
//                     <Table.Summary.Cell type='danger'>
//                       <span>
//                         {' '}
//                         <b>{commaNumber((totalAmount * 5) / 100)} $</b>
//                       </span>
//                     </Table.Summary.Cell>
//                   </Table.Summary.Row>
//                 </>
//               );
//             }}
//             columns={columns}
//             dataSource={result}
//             pagination={false}
//           />
//         </Container>
//       )}
//     </DefaultLayout>
//   );
// };

// export default BonusMatchingSummaryNew;

