import React, { useState, useEffect, ChangeEvent } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
   approveDeposit,
   getDepositsById2,
   reset
} from '../features/deposit/depositSlice';
import {
   Button,
   Form,
   Typography,
   Image,
   Modal,
   Select,
   Statistic,
   Card,
   Row,
   Col,
} from 'antd';
import { FaCheck, FaPlus, FaTimes } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';
import styled from 'styled-components';

import Bay from '../assets/icon/th/bay.svg';
import { AiOutlineBank, AiOutlineUpload } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import Loading from '../components/Loading';

const { Title, Text } = Typography;
const { Option } = Select;

const ApproveDeposit = () => {
   const id = useParams().id;

   const navigate = useNavigate();

   const isMobile = useMediaQuery({ maxWidth: 370 });
   const isMobileL = useMediaQuery({ maxWidth: 500 });


   const { userInfo } = useSelector((state) => state.auth);
   const dispatch = useDispatch();
   const { deposit, isConfirmError, message, isConfirmSuccess, isLoading } = useSelector(
      (state) => state.deposit
   );

   const [dataMessage , setDataMessage] = useState('');

   const [fileList, setFileList] = useState([]);
   const [previewImages, setPreviewImages] = useState([]);

   const [isBankSelected, setIsBankSelected] = useState(false);

   const [modalError, setModalError] = useState(false);
   const [modalSuccess, setModalSuccess] = useState(false);


   useEffect(() => {
      if (isConfirmError) {
         setDataMessage(message);
         setModalError(true);
      }

      if (isConfirmSuccess) {
         setModalSuccess(true);
      }

      dispatch(getDepositsById2(id));

      return () => {
         dispatch(reset());
      };
   }, [dispatch, id, isConfirmError, isConfirmSuccess,message]);



   useEffect(() => {
      // Cleanup object URLs when the component is unmounted
      return () => {
         previewImages.forEach((imageUrl) => URL.revokeObjectURL(imageUrl));
      };
   }, [previewImages]);

   const handleFileChange = (event) => {
      const files = event.target.files;
   
      if (files) {
         const newFiles = Array.from(files);
   
         newFiles.forEach((file) => {
            const reader = new FileReader();
   
            reader.onload = (e) => {
               const base64 = e.target.result;
   
               const base64File = {
                  originFileObj: base64,
                  name: file.name,
                  status: 'done',
                  uid: file.uid || Date.now(), // Ensure unique UID
               };
   
               setFileList((prevList) => [...prevList, base64File]);
               setPreviewImages((prevImages) => [...prevImages, base64]);
            };
   
            reader.readAsDataURL(file); // Convert file to base64
         });
      }
   };
   

   const handlePreview = (file) => {
      let previewUrl = file.url;
      if (!previewUrl && file.originFileObj) {
         previewUrl = URL.createObjectURL(file.originFileObj);
      }

      const img = new Image();
      img.src = previewUrl;
      const imgWindow = window.open(previewUrl);
      imgWindow?.document.write(img.outerHTML);
   };

   const handleSubmit = () => {
      if (fileList.length === 0) {
         message.error('กรุณาอัพโหลดสลิปก่อนส่ง');
         return;
      }

      dispatch(approveDeposit({ id, images: fileList }));
      //   message.success('ส่งข้อมูลเรียบร้อย');
   };

   if(isLoading) {
      return <Loading />
   }



   return (
      <DefaultLayout>
      <StyledContainer>
      <StyledCard>
        <Card>
          <h2 className="text-2xl font-bold text-gray-800 text-center">ช่องทางชำระเงิน</h2>
        </Card>
        <div>
          <Form layout="vertical">
            {/* User Information */}
            <Form.Item 
              label={<span className="text-lg font-semibold">ข้อมูลสมาชิก</span>}
              className="mb-4"
            >
              <div className="p-3 bg-gray-50 rounded-md">
                {userInfo.userId} - {userInfo.username}
              </div>
            </Form.Item>

            {/* Bank Selection */}
            <Form.Item
              label={<span className="text-lg font-semibold">เลือกบัญชีธนาคาร</span>}
              className="mb-4"
            >
              <Select
                placeholder="กรุณาเลือกธนาคาร"
                onChange={(value) => setIsBankSelected(value)}
                className="w-full"
              >
                <Option value="BAY-7101164370">
                  <div className="flex items-center justify-between">
                    <span>BAY-7101164370</span>
                    <AiOutlineBank  className="text-xl" />
                  </div>
                </Option>
              </Select>
            </Form.Item>

            {/* Bank Account Details */}
            {isBankSelected && (
              <BankAccountInfo>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-gray-600 mb-1">ธนาคาร</p>
                    <p className="font-semibold">กรุงศรีอยุธยา</p>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-1">ชื่อบัญชี</p>
                    <p className="font-semibold">บจก.สยามไพรพลัส(อินเตอร์)</p>
                  </div>
                  <div className="col-span-2">
                    <p className="text-gray-600 mb-1">ยอดชำระเงิน</p>
                    <Statistic 
                      value={deposit.deposit.amount} 
                      prefix="฿"
                      className="text-primary font-bold"
                    />
                  </div>
                </div>
              </BankAccountInfo>
            )}

            {/* Upload Section */}
            <UploadSection>
              <h3 className="text-xl font-bold mb-4">อัพโหลดสลิปการโอนเงิน</h3>
              <p className="text-gray-600 mb-4">โปรดอัพโหลดสลิปการโอนเพื่อยืนยันการทำรายการ</p>
              
              <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg cursor-pointer hover:border-blue-500 transition-colors">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  className="invisible"
                  id="slip-upload"
                />
                <label htmlFor="slip-upload" className="cursor-pointer">
                  <AiOutlineUpload
                  size={64}
                  className="text-4xl text-gray-400 mb-2" />
                  <p className="text-gray-600">ลากไฟล์มาที่นี่หรือคลิกเพื่ออัพโหลด</p>
                  <p className="text-sm text-gray-500">รองรับเฉพาะไฟล์รูปภาพ (JPEG/PNG)</p>
                </label>
              </div>

              {/* Image Preview */}
              {previewImages.length > 0 && (
                <ImagePreviewContainer>
                  {previewImages.map((url, index) => (
                    <Image
                      key={index}
                      src={url}
                      alt={`Slip Preview ${index + 1}`}
                      className="rounded-lg"
                    />
                  ))}
                </ImagePreviewContainer>
              )}

              <Button
                type="primary"
                size="large"
                className="w-full mt-6"
                style={{marginTop: '16px'}}
                onClick={handleSubmit}
                disabled={!isBankSelected || previewImages.length === 0}
              >
                อัพโหลดสลิป เพื่อยืนยันการเติมพีวี
              </Button>
            </UploadSection>
          </Form>
        </div>
      </StyledCard>

      {/* Success Modal */}
      <Modal
        visible={modalSuccess}
        footer={null}
        onCancel={() => {
          setModalSuccess(false)
        //  window.location.reload('/depositrecord');
          navigate('/depositrecord')
        }}
        centered
      >
        <div className="text-center py-6">
          <FaCheck size={64} color='green' className="text-6xl text-green-500 mb-4" />
          <h3 className="text-xl font-bold mb-2">รายการสำเร็จ</h3>
          <p>ระบบได้รับสลิปของท่านเรียบร้อยแล้ว</p>
        </div>

        <div>
               <div className=' pb-2' style={{ height: '60vh' }}>
                  <p>
                     <span>รายการสั่งซื้อของคุณ: </span> {userInfo.username} -
                     รหัสสมาชิก {userInfo.userId}
                  </p>
                  <div
                     className={`${
                        isMobile
                           ? 'd-flex flex-column justify-content-start text-align-start '
                           : 'd-flex justify-content-between '
                     }`}
                  >
                     <div>
                        <h5>หมายเลขใบเสร็จ</h5>
                        <h5>{deposit?.deposit?.refNumber}</h5>
                     </div>
                     <div
                        className={`${
                           isMobile ? 'text-start mt-3' : 'text-end'
                        }`}
                     >
                        <h5>วันที่ทำรายการ</h5>
                        <p style={{ fontSize: '16px' }}>
                           <b>{moment(deposit?.deposit?.createdAt).format('LLL')}</b>
                        </p>
                     </div>
                  </div>
                  <hr />
                  <div className='d-flex justify-content-between pb-2'>
                     <div className=''>
                        <h5>รายการสินค้า </h5>

                        {deposit?.deposit?.totalProduct?.map((e) => {
                           return (
                              <h5 style={{ fontSize: '16px' }}>
                                 <b>{e.name}</b>
                              </h5>
                           );
                        })}
                     </div>
                     <div>
                        <h5 style={{ textAlign: 'end' }}>จำนวน </h5>
                        {deposit?.deposit?.totalProduct?.map((e) => {
                           return (
                              <h5 style={{ fontSize: '16px' }}>
                                 <b>{e.amount}: ชิ้น</b>
                              </h5>
                           );
                        })}
                     </div>
                  </div>
                  <hr />
                  <div className=''>
                     <Row>
                        <Col xs={12} sm={12} md={18}>
                           <p
                              className={`${
                                 isMobile ? 'text-start' : 'text-end'
                              }`}
                              style={{
                                 marginBottom: '0',
                                 fontSize: '18px',
                              }}
                           >
                              <b>Pv ที่ได้รับ</b> :{' '}
                           </p>
                           <br />
                           <p
                              className={`${
                                 isMobile ? 'text-start' : 'text-end'
                              }`}
                              style={{
                                 marginBottom: '0',
                                 fontSize: '18px',
                              }}
                           >
                              <b>ราคาสินค้าทั้งหมด</b>
                           </p>
                        </Col>
                        <Col xs={12} sm={6} md={6}>
                           <Statistic
                              valueStyle={{ textAlign: 'end' }}
                              value={deposit?.deposit?.totalPv?.toFixed(0)}
                              suffix='PV'
                           />
                           <br />
                           <Statistic
                              valueStyle={{ textAlign: 'end', color: 'green' }}
                              value={deposit?.deposit?.amount?.toFixed(0)}
                              suffix='บาท'
                           />
                        </Col>
                     </Row>
                  </div>
               </div>
            </div>
      </Modal>

      {/* Error Modal */}
      <Modal
        visible={modalError}
        footer={null}
        onCancel={() =>{
          setModalError(false)
          window.location.reload();
         }}

        centered
      >
        <div className="text-center py-6">
          <FaTimes size={64} color='red' className="text-6xl text-red-500 mb-4" />
          <h3 className="text-xl font-bold mb-2">รายการไม่สำเร็จ</h3>
          <>
    
            <p>{dataMessage}</p>
        

          
          </>
          <p>กรุณาลองใหม่อีกครั้ง</p>
        </div>
      </Modal>
    </StyledContainer>
      </DefaultLayout>
   );
};

export default ApproveDeposit;


// Styled Components
const StyledContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const StyledCard = styled(Card)`
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const BankAccountInfo = styled.div`
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 1rem 0;
`;

const UploadSection = styled.div`
  text-align: center;
  padding: 2rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ImagePreviewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const TextStyle = styled.p`
   margin: 0 !important;
   border-bottom: 1px dashed;
   font-size: large;
`;
