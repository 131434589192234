import { Card, Col, Row, Input, Button, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import {
   cancelOrderById,
   getOrderById,
   getOrdersPaid,
   getOrdersPaidById,
} from '../features/order/orderSlice';
import { Container } from 'react-bootstrap';
import moment from 'moment';
import { FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';
import { RiArrowLeftDoubleFill, RiArrowRightDoubleFill } from 'react-icons/ri';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { GiConfirmed } from 'react-icons/gi';
import { TbAlertTriangleFilled } from 'react-icons/tb';

const { Search } = Input;

const OrdersPage = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const isDesktop = useMediaQuery({ minWidth: 1025 });
   const isLaptop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
   const isTablet = useMediaQuery({ maxWidth: 770 });
   const isMobile = useMediaQuery({ maxWidth: 539 });

   const [modalError, setModalError] = useState(false);
   const [modalSuccess, setModalSuccess] = useState(false);

   const [modalConfirm, setModalConfirm] = useState(false);

   const cardStyle = {
      width: isMobile ? '95%' : isTablet ? '90%' : isLaptop ? '70%' : '400px', // Default for desktop
      borderRadius: '20px',
      padding: isMobile ? '10px' : '20px',
      margin: '0 auto',
   };

   const canceledLabelStyle = {
      position: 'absolute',
      bottom: isMobile ? '5%' : '10%',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: 'rgba(63, 139, 66, 0.8)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '10px',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: isMobile ? '12px' : '14px',
   };
   const [isHovered, setIsHovered] = useState(false);

   const canceledLabelStyle2 = {
      position: 'absolute',
      bottom: isMobile ? '5%' : '10%',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: isHovered
         ? 'rgba(200, 0, 0, 0.8)'
         : 'rgba(255, 0, 0, 0.8)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '10px',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: isMobile ? '12px' : '14px',
      cursor: 'pointer', // Indicate interactivity
      transition: 'background-color 0.3s ease', // Smooth hover transition
   };
   const But = styled.button`
      background-color: ${isHovered
         ? 'rgba(200, 0, 0, 0.8)'
         : 'rgba(255, 0, 0, 0.8)'};
      color: white;
      padding: 5px 10px;
      border-radius: 10px;
      font-weight: bold;
      text-align: center;
      font-size: ${isMobile ? '12px' : '14px'};
      cursor: pointer;
      transition: background-color 0.3s ease;
      border: none;
      outline: none;

      &:hover {
         background-color: rgba(200, 0, 0, 0.8);
      }
   `;

   const cancelButtonStyle = {
      backgroundColor: 'red',
      color: 'white',
      position: 'absolute',
      top: isMobile ? '5px' : '10px',
      right: isMobile ? '5px' : '10px',
      borderRadius: '10px',
   };

   const [page, setPage] = useState(1);

   const [isAnother, setIsAnother] = useState(false);

   const { userInfo } = useSelector((state) => state.auth);
   const {
      orders,
      orders2,
      ordersPaid,
      isCancelSuccess,
      isCancelError,
      message,
      hasMore,
   } = useSelector((state) => state.order);

   //  const [orderId, setOrderId] = useState(null);
   const [orderData, setOrderData] = useState({});

   console.log('ordersPaid', ordersPaid);
   console.log('orders', orders);
   console.log('orders2', orders2);

   useEffect(() => {
      dispatch(getOrderById({ id: userInfo._id, page: page, limit: 3 }));
   }, [dispatch, userInfo._id, page]);

   useEffect(() => {
      if (page >= 1) {
         dispatch(getOrdersPaid(page));
      }

      if (isCancelError) {
         setModalError(true);
         setModalConfirm(false);
      }
      if (isCancelSuccess) {
         setModalSuccess(true);
         setModalConfirm(false);
      }
   }, [dispatch, page, isCancelError, isCancelSuccess]);

   const handlePreviousPage = () => {
      setPage((prevPage) => Math.max(prevPage - 1, 1));
   };

   const handleNextPage = () => {
      if (ordersPaid.currentPage < ordersPaid.totalPages) {
         setPage((prevPage) => prevPage + 1);
      }
   };
   const handleFirstPage = () => {
      setPage(1);
   };

   const handleLastPage = () => {
      setPage(ordersPaid.totalPages);
   };

   const onSearch = (value) => dispatch(getOrdersPaidById(value));

   const handleCancelOrder = (orderData) => {
      console.log('orderData', orderData);

      dispatch(cancelOrderById(orderData._id));

      // const confirm = window.confirm('Confirm to cancel this order?');

      // if (confirm) {
      //    dispatch(cancelOrderById(id));
      // }
   };

   const loadMore = () => {
      setPage((prevPage) => prevPage + 1);
   };

   return (
      <DefaultLayout>
         <Container>
            <div className='my-5'>
               <h3>
                  <b>รายการสั่งซื้อ</b>
               </h3>
               <div className='d-flex gap-4 my-3'>
                  <Tabs>
                     <Button
                        className='btn-primary'
                        onClick={() => {
                           setIsAnother(false);
                           dispatch(
                              getOrderById({
                                 id: userInfo._id,
                                 page: page,
                                 limit: 10,
                              })
                           );
                        }}
                     >
                        รายการสมาชิก
                     </Button>
                     <Button
                        className='btn-secondary'
                        onClick={() => {
                           setIsAnother(true);
                           dispatch(getOrdersPaid());
                        }}
                     >
                        ซื้อให้สมาชิกอื่น
                     </Button>
                  </Tabs>
               </div>

               <div className='mb-4'>
                  <Search
                     placeholder='ค้นหาด้วยรหัสสมาชิก'
                     onSearch={onSearch}
                     style={{
                        width: 200,
                     }}
                  />
               </div>

               {orders2.length > 0 ? (
                  <div>
                     <Row gutter={[10, 10]}>
                        {orders2.map((e) => {
                           const today = new Date();
                           today.setHours(0, 0, 0, 0);

                           const orderDate = new Date(e.createdAt);
                           orderDate.setHours(0, 0, 0, 0);
                           const isOrderFromToday =
                              orderDate.getTime() === today.getTime();
                           return (
                              <Col sm={24} xs={24} md={12} lg={12} xl={8}>
                                 <Card
                                    className='position-relative
                     
                        '
                                    style={cardStyle}
                                 >
                                    {/* Canceled Label */}
                                    {e.status === false ? (
                                       <div style={canceledLabelStyle}>
                                          <b className=' text-white'>
                                             ยกเลิกสำเร็จ
                                          </b>
                                       </div>
                                    ) : (
                                       <>
                                          {isOrderFromToday &&
                                             e.status === true && (
                                                <But
                                                   // onClick={() =>
                                                   //    handleCancelOrder(e._id)
                                                   // }
                                                   onClick={() => {
                                                      setOrderData(e);
                                                      setModalConfirm(
                                                         true
                                                      );
                                                   }}
                                                   style={canceledLabelStyle2}
                                                >
                                                   <b className=' text-white'>
                                                      ยกเลิกรายการ
                                                   </b>
                                                </But>
                                             )}
                                       </>
                                    )}

                                    <div className='d-flex flex-row justify-content-between'>
                                       {/* Left Section */}
                                       <div className='mb-3'>
                                          <span>
                                             หมายเลขอ้างอิง -
                                             <span
                                                style={{
                                                   fontSize: '18px',
                                                }}
                                             >
                                                <b>{e.refNumber}</b>
                                             </span>
                                          </span>
                                          <p
                                             style={{
                                                fontSize: '12px',
                                             }}
                                          >
                                             {moment(e.createdAt).format(
                                                'DD/MM/YYYY'
                                             )}
                                          </p>
                                          <p>
                                             <b>{e?.user?.name}</b>
                                          </p>
                                          <p>
                                             <b>{e?.user?.userId}</b>
                                          </p>
                                       </div>

                                       {/* Right Section */}
                                       <div
                                          className={`${
                                             isMobile
                                                ? 'text-left'
                                                : 'text-right'
                                          }`}
                                       >
                                          {e.totalPv === 500 ? (
                                             <h5>
                                                <b>1 Holder</b>
                                             </h5>
                                          ) : (
                                             <h5>
                                                <b>1 Member</b>
                                             </h5>
                                          )}
                                          <p>
                                             <b>{e.note}</b>
                                          </p>
                                          <p>
                                             <b>{e.totalPv}PV</b>
                                          </p>
                                       </div>
                                    </div>
                                 </Card>
                              </Col>
                           );
                        })}
                     </Row>
                  </div>
               ) : (
                  <>
                     {ordersPaid?.orders?.length > 0 && isAnother ? (
                        <>
                           <div>
                              <Row gutter={[10, 10]}>
                                 {ordersPaid?.orders?.map((e) => {
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0);

                                    const orderDate = new Date(e.createdAt);
                                    orderDate.setHours(0, 0, 0, 0);
                                    const isOrderFromToday =
                                       orderDate.getTime() === today.getTime();
                                    return (
                                       <Col
                                          sm={24}
                                          xs={24}
                                          md={12}
                                          lg={12}
                                          xl={8}
                                       >
                                          <Card
                                             className='position-relative
                                 
                                    '
                                             style={cardStyle}
                                          >
                                             {/* Canceled Label */}
                                             {e.status === false ? (
                                                <div style={canceledLabelStyle}>
                                                   <b className=' text-white'>
                                                      ยกเลิกสำเร็จ
                                                   </b>
                                                </div>
                                             ) : (
                                                <>
                                                   {isOrderFromToday &&
                                                      e.status === true && (
                                                         <But
                                                            // onClick={() =>
                                                            //    handleCancelOrder(
                                                            //       e._id
                                                            //    )
                                                            // }
                                                            onClick={() => {
                                                               // setOrderId(e._id)
                                                               setOrderData(e);
                                                               setModalConfirm(
                                                                  true
                                                               );
                                                            }}
                                                            style={
                                                               canceledLabelStyle2
                                                            }
                                                         >
                                                            <b className=' text-white'>
                                                               ยกเลิกรายการ
                                                            </b>
                                                         </But>
                                                      )}
                                                </>
                                             )}

                                             <div className='d-flex flex-row justify-content-between'>
                                                {/* Left Section */}
                                                <div className='mb-3'>
                                                   <span>
                                                      หมายเลขอ้างอิง -
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         <b>{e.refNumber}</b>
                                                      </span>
                                                   </span>
                                                   <p
                                                      style={{
                                                         fontSize: '12px',
                                                      }}
                                                   >
                                                      {moment(
                                                         e.createdAt
                                                      ).format('DD/MM/YYYY')}
                                                   </p>
                                                   <p>
                                                      <b>{e?.user?.name}</b>
                                                   </p>
                                                   <p>
                                                      <b>{e?.user?.userId}</b>
                                                   </p>
                                                   {e.note === 'สมัครสมาชิก' &&
                                                      e.user === null && (
                                                         <>
                                                            <p>
                                                               <b>
                                                                  {e.userName}
                                                               </b>
                                                            </p>
                                                            <p>
                                                               <b>{e.userId}</b>
                                                            </p>
                                                         </>
                                                      )}
                                                </div>

                                                {/* Right Section */}
                                                <div
                                                   className={`${
                                                      isMobile
                                                         ? 'text-left'
                                                         : 'text-right'
                                                   }`}
                                                >
                                                   {e.totalPv === 500 ? (
                                                      <h5>
                                                         <b>1 Holder</b>
                                                      </h5>
                                                   ) : (
                                                      <h5>
                                                         <b>1 Member</b>
                                                      </h5>
                                                   )}
                                                   <p>
                                                      <b>{e.note}</b>
                                                   </p>
                                                   <p>
                                                      <b>{e.totalPv}PV</b>
                                                   </p>
                                                </div>
                                             </div>
                                          </Card>
                                       </Col>
                                    );
                                 })}
                              </Row>
                           </div>
                        </>
                     ) : (
                        <>
                           <div>
                              <Row gutter={[10, 10]}>
                                 {orders.map((e) => {
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0);

                                    const orderDate = new Date(e.createdAt);
                                    orderDate.setHours(0, 0, 0, 0);
                                    const isOrderFromToday =
                                       orderDate.getTime() === today.getTime();
                                    return (
                                       <Col
                                          sm={24}
                                          xs={24}
                                          md={12}
                                          lg={12}
                                          xl={8}
                                       >
                                          <Card
                                             className='position-relative
                               
                                  '
                                             style={cardStyle}
                                          >
                                             {/* Canceled Label */}
                                             {e.status === false ? (
                                                <div style={canceledLabelStyle}>
                                                   <b className=' text-white'>
                                                      ยกเลิกสำเร็จ
                                                   </b>
                                                </div>
                                             ) : (
                                                <>
                                                   {isOrderFromToday &&
                                                      e.status === true && (
                                                         <But
                                                            // onClick={() =>
                                                            //    handleCancelOrder(
                                                            //       e._id
                                                            //    )
                                                            // }
                                                            onClick={() => {
                                                               //  setOrderId(e._id)
                                                               setModalConfirm(
                                                                  true
                                                               );
                                                            }}
                                                            style={
                                                               canceledLabelStyle2
                                                            }
                                                         >
                                                            <b className=' text-white'>
                                                               ยกเลิกรายการ
                                                            </b>
                                                         </But>
                                                      )}
                                                </>
                                             )}

                                             <div className='d-flex flex-row justify-content-between'>
                                                {/* Left Section */}
                                                <div className='mb-3'>
                                                   <span>
                                                      หมายเลขอ้างอิง -
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         <b>{e.refNumber}</b>
                                                      </span>
                                                   </span>
                                                   <p
                                                      style={{
                                                         fontSize: '12px',
                                                      }}
                                                   >
                                                      {moment(
                                                         e.createdAt
                                                      ).format('DD/MM/YYYY')}
                                                   </p>
                                                   <p>
                                                      <b>{e?.user?.name}</b>
                                                   </p>
                                                   <p>
                                                      <b>{e?.user?.userId}</b>
                                                   </p>
                                                </div>

                                                {/* Right Section */}
                                                <div
                                                   className={`${
                                                      isMobile
                                                         ? 'text-left'
                                                         : 'text-right'
                                                   }`}
                                                >
                                                   {e.totalPv === 500 ? (
                                                      <h5>
                                                         <b>1 Holder</b>
                                                      </h5>
                                                   ) : (
                                                      <h5>
                                                         <b>1 Member</b>
                                                      </h5>
                                                   )}
                                                   <p>
                                                      <b>{e.note}</b>
                                                   </p>
                                                   <p>
                                                      <b>{e.totalPv}PV</b>
                                                   </p>
                                                </div>
                                             </div>
                                          </Card>
                                       </Col>
                                    );
                                 })}
                              </Row>
                              {hasMore && (
                                 <div className='d-flex justify-content-center mt-3'>
                                    <Button type='primary' onClick={loadMore}>Loadmore</Button>
                                 </div>
                              )}
                           </div>
                           {!hasMore && <p>No more orders to load</p>}
                        </>
                     )}
                  </>
               )}

               {/* Pagination Controls */}
               {isAnother && (
                  <>
                     <div className='d-flex justify-content-around mt-2 mb-5'>
                        <RiArrowLeftDoubleFill
                           className='cursor-pointer'
                           onClick={handleFirstPage}
                           disabled={page === 1}
                           size={32}
                        ></RiArrowLeftDoubleFill>
                        <FaArrowLeft
                           className='cursor-pointer'
                           onClick={handlePreviousPage}
                           disabled={page === 1}
                           size={32}
                        ></FaArrowLeft>
                        <span>
                           <b>
                              หน้า {ordersPaid.currentPage} จาก{' '}
                              {ordersPaid.totalPages}
                           </b>
                        </span>

                        <FaArrowRight
                           className='cursor-pointer'
                           onClick={handleNextPage}
                           disabled={
                              ordersPaid.currentPage === ordersPaid.totalPages
                           }
                           size={32}
                        ></FaArrowRight>
                        <RiArrowRightDoubleFill
                           className='cursor-pointer'
                           onClick={handleLastPage}
                           disabled={
                              ordersPaid.currentPage === ordersPaid.totalPages
                           }
                           size={32}
                        ></RiArrowRightDoubleFill>
                     </div>
                  </>
               )}
            </div>
         </Container>
         <Modal
            title='ยืนยันการทำรายการ'
            visible={modalConfirm}
            footer={false}
            onCancel={() => {
               setModalConfirm(false);
            }}
         >
            <div className='text-center'>
               <div className='text-center'>
                  {' '}
                  <TbAlertTriangleFilled
                     size={128}
                     color='#ffda30'
                     className='text-center'
                  />
               </div>
               <h5
                  style={{
                     fontSize: '30px',
                     textAlign: 'center',
                     color: 'red',
                  }}
               >
                  <b> ยืนยันการยกเลิกรายการสั่งซื้อ</b>{' '}
               </h5>
               {orderData && (
                  <>
                     {/* <p
                className="mb-2"
                style={{ fontSize: "16px", textAlign: "center" }}
              >
                <b>คุณยืนยันต้องการยกเลิกรายการสั่งซื้อ</b>
              </p> */}
                     <p
                        className='mb-0'
                        style={{ fontSize: '16px', textAlign: 'center' }}
                     >
                        <b>
                           {' '}
                           รหัสสมาชิกรับการยกเลิกรายการสั่งซื้อ
                           <span className='text-danger ml-2'>
                              {orderData.userId}
                           </span>{' '}
                        </b>
                     </p>
                     <p style={{ fontSize: '16px', textAlign: 'center' }}>
                        <b>
                           {' '}
                           PV คืนไปที่รหัส {orderData?.userOrder?.userId}{' '}
                           <span className='text-primary'>
                              จำนวน {orderData?.totalPv} PV
                           </span>
                        </b>
                     </p>
                  </>
               )}
               <br />
               <div className='d-flex'>
                  <br />
                  <br />
                  <Button
                     style={{ backgroundColor: '#ffda30' }}
                     block
                     size='large'
                     onClick={() => setModalConfirm(false)}
                  >
                     <h5>
                        <b>ยกเลิก</b>
                     </h5>
                  </Button>{' '}
                  <Button
                     block
                     size='large'
                     type='primary'
                     htmlType='submit'
                     onClick={(event) => handleCancelOrder(orderData)}
                  >
                     <h5 style={{ color: '#ffda30' }}>
                        <b>ยืนยัน</b>
                     </h5>
                  </Button>
               </div>
            </div>
         </Modal>
         <Modal
            title='รายการไม่สำเร็จ'
            visible={modalError}
            footer={false}
            onCancel={() => {
               setModalError(false);
               window.location.reload();
            }}
         >
            <div className='text-center'>
               <FaTimes size={128} color='red' />
               <h5>
                  <b>{message}</b>
               </h5>
            </div>
         </Modal>
         <Modal
            title='ทำรายการสำเร็จ'
            style={{ height: '60%' }}
            visible={modalSuccess}
            footer={false}
            onCancel={() => {
               setModalSuccess(false);
               window.location.reload();
            }}
         >
            <div className='text-center mb-3'>
               <GiConfirmed size={64} color='#4cbf2d' />
            </div>
            <h4
               style={{ fontSize: '30px' }}
               className='text-center mb-4 text-danger'
            >
               {' '}
               <b>ทำรายการสำเร็จ</b>{' '}
            </h4>

            {/* <div className='my-2 text-center'>
                     <Image src={SuccessIcon} width='50%' preview={false} />
                  </div> */}
            <br />
         </Modal>
      </DefaultLayout>
   );
};

export default OrdersPage;

const Tabs = styled.div`
   display: flex;
   gap: 20px;
   margin-bottom: 20px;

   h5 {
      font-weight: bold;
      cursor: pointer;
      &:hover {
         color: #1890ff;
      }
   }
`;
